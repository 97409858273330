import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import hideModal from "../../components/HideModal";
import Axios from "axios";
import { server, config } from "../../routes/env";
import Spinner from "../../components/Spinner";
import { Notification } from "react-notification";

export default class Licenses extends PureComponent {
  state = {
    url: "/partner/screen/read",
    screens: [],
    next: null,
    total_logs: 0,
    spinner: (
      <Spinner
        color={
          localStorage.getItem("theme-mode") === "dark" ? "#b5b5b7" : "#686769"
        }
      />
    ),
    show_more_button: "Show more",
    notification: false,
    message: "",
  };

  componentDidMount = () => {
    this.read_screens(this.state.url);
  };

  read_screens = (url, search = null) => {
    this.setState({
      show_more_button: this.state.spinner,
    });
    Axios.get(server + url, config).then((rsp) => {
      this.setState({
        total_screens: rsp.data.count,
        screens: search
          ? rsp.data.results
          : this.state.screens.concat(rsp.data.results),
        next:
          rsp.data.next == null
            ? null
            : rsp.data.next.replace(/^.*\/\/[^\/]+/, ""),
        show_more_button:
          rsp.data.next == null ? "All are catch up!" : "Show more",
      });
    });
  };

  search = (event) => {
    this.read_screens(this.state.url + "?search=" + event.target.value, true);
  };

  show_more = (url) => {
    this.read_screens(url);
  };

  render() {
    const {
      screens,
      show_more_button,
      total_screens,
      next,
      add_user_loader,
      notification,
      message,
    } = this.state;

    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <Notification
          isActive={notification}
          message={message}
          dismissAfter={4000}
          onDismiss={() => this.setState({ notification: false })}
        />
        <div className="card mt-4">
          <div className="d-flex row justify-content-between p-3">
            <div className="col-md-6">
              <h3 className="card-title h5 mb-0 mt-1">Licenses</h3>
            </div>
          </div>
        </div>

        <div className="card py-2 mt-4 mb-5">
          <div className="card-header bg-transparent border-0 p-3">
            <div className="row justify-content-between">
              <div className="col-md-4">
                <button
                  className="btn btn-sm btn-primary px-3"
                  data-toggle="modal"
                  data-target="#purchase_license_modal"
                >
                  Purchase Licenses
                </button>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control text-muted rounded-custom pl-3"
                  placeholder="Search by screen name"
                  onChange={this.search}
                  required
                />
              </div>
            </div>
          </div>

          <div className="card-body p-0 border-0">
            <div className="table-responsive">
              <table className="table table-striped table-hover mb-0">
                <thead>
                  <tr className="text-center text-muted">
                    <th>#</th>
                    <th>UserName</th>
                    <th>Email</th>
                    <th>Screen Name</th>
                    <th>Added</th>
                    {/* <th>Days Left</th> */}
                    {/* <th>Renew</th> */}
                  </tr>
                </thead>
                <tbody>
                  {screens.map((data, index) => (
                    <tr key={index}>
                      <td className="td-pt-3 text-center">#{index + 1}</td>
                      <td className="td-pt-3 text-center">{data.user.name}</td>
                      <td className="td-pt-3 text-center">
                        <a
                          target="_blank"
                          rel="noopner noreferrer"
                          className="text-decoration-none"
                          href={"mailto:" + data.user.email}
                        >
                          {data.user.email}
                        </a>
                      </td>
                      <td className="td-pt-3 text-center">
                        {data.screen.name}
                      </td>
                      <td className="td-pt-3 text-center">
                        {new Date(data.screen.created).toDateString()}
                      </td>
                      {/* <td className="text-center">
                      <button className="btn btn-sm btn-primary">Renew</button>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="card-footer bg-transparent border-0 p-3">
            <div className="row">
              <div className="col-6 text-muted">
                <h6 className="h6 pt-2 small">
                  Showing {screens.length} of {total_screens}
                </h6>
              </div>

              <div className="col-6 text-right">
                <button
                  className="btn btn-white btn-sm rounded-custom border px-3"
                  onClick={next == null ? null : () => this.read_screens(next)}
                >
                  {show_more_button}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          key="purchase_license_modal"
          id="purchase_license_modal"
          title="Purchase License"
        >
          <div className="modal-body p-4 pt-0">
            <h5>
              Contact wilyer support team for purchase willplay & signage
              licenses
            </h5>{" "}
            <br />
            <div className="text-left">
              <Link
                to="/contact"
                onClick={() => hideModal(0)}
                className="btn btn-sm btn-primary"
              >
                Contact Wilyer Support
              </Link>
            </div>
          </div>
        </Modal>
      </main>
    );
  }
}
