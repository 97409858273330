import React, { PureComponent } from "react";
import Axios from "axios";
import { server, config } from "../../routes/env";

export default class Dashboard extends PureComponent {
  state = {
    clients: 0,
    screens: 0,
    signage: 0,
    willplay: 0,
  };

  componentDidMount = () => {
    Axios.get(server + "/partner/dashboard", config).then((rsp) => {
      this.setState({
        clients: rsp.data.clients,
        screens: rsp.data.screens,
        signage: rsp.data.signage_licenses,
        willplay: rsp.data.willplay_licenses,
      });
    });
  };

  render() {
    const { clients, screens, signage, willplay } = this.state;
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4">
          <div className="d-flex row justify-content-between p-3">
            <div className="col-md-6">
              <h3 className="card-title h5 mb-0 mt-1">Dashboard</h3>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          {/* Your clients */}
          <div className="col-lg-4">
            <div className="card mt-4">
              <div className="card-body">
                <div className="align-items-center">
                  <div className="col mx-3">
                    <h3 className="clients">{clients}</h3>
                    <h5>Clients</h5>
                    <p className="small text-muted mb-0">
                      <time>
                        Your{" "}
                        {clients <= 1
                          ? clients + " client is "
                          : clients + " clients are "}
                        using wilyer signage.
                      </time>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Total Screens */}
          <div className="col-lg-4">
            <div className="card mt-4">
              <div className="card-body">
                <div className="align-items-center">
                  <div className="col mx-3">
                    <h3>{screens}</h3>
                    <h5>Screens</h5>
                    <p className="small text-muted mb-0">
                      <time>
                        Your{" "}
                        {clients <= 1
                          ? clients + " client "
                          : clients + " clients "}
                        clients have{" "}
                        {screens <= 1
                          ? screens + " screen "
                          : screens + " screens "}
                        on wilyer signage.
                      </time>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Total Screens */}
          <div className="col-lg-4">
            <div className="card mt-4">
              <div className="card-body">
                <div className="align-items-center">
                  <div className="col mx-3">
                    <h3 className="clients">{signage}</h3>
                    <h5>Signage Licenses Left</h5>
                    <p className="small text-muted mb-0">
                      <time>
                        {signage <= 1
                          ? signage + " signage license is "
                          : signage + " signage licenses  are "}
                        left in your account <br />
                        (Cloud Based)
                      </time>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Total Screens */}
          <div className="col-lg-4">
            <div className="card mt-4">
              <div className="card-body">
                <div className="align-items-center">
                  <div className="col mx-3">
                    <h3 className="clients">{willplay}</h3>
                    <h5>Willplay Licenses Left</h5>
                    <p className="small text-muted mb-0">
                      <time>
                        {willplay <= 1
                          ? willplay + " willplay license is "
                          : willplay + " willplay licenses  are "}
                        left in your account <br /> (Pendrive Based)
                      </time>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Total Screens */}
          {/* <div className="col-lg-4">
            <div className="card mt-4">
              <div className="card-body">
                <div className="align-items-center">
                  <div className="col mx-3">
                    <h3 className="clients">8</h3>
                    <h5>Licenses Expiring soon</h5>
                    <p className="small text-muted mb-0">
                      <time>Your clients licences are expiring soon.</time>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* Total Products */}
          {/* <div className="col-lg-4">
            <div className="card mt-4">
              <div className="card-body">
                <div className="align-items-center">
                  <div className="col mx-3">
                    <h3 className="clients">8</h3>
                    <h5>Products on wilyer shop</h5>
                    <p className="small text-muted mb-0">
                      <time>Your clients licences are expiring soon.</time>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </main>
    );
  }
}
