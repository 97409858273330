// export const server = "http://localhost:8000";
export const server = "https://api.wilyersignage.com";

export var auth;

if (server.includes("localhost")) {
  auth = "http://localhost:3000";
} else {
  auth = "https://auth.wilyersignage.com";
}

export const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};
export default {
  server,
  auth,
  config,
};
