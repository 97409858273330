import React, { PureComponent } from "react";
import { Notification } from "react-notification";
import Axios from "axios";
import Spinner from "../../components/Spinner";
import { config, server } from "../../routes/env";
import resetForm from "../../components/ResetForm";

export default class Screen extends PureComponent {
  state = {
    loader: "",
    message: "",
    notification: false,
  };
  create = async (event) => {
    event.preventDefault();

    this.setState({
      loader: <Spinner />,
    });

    const params = {
      subject: event.target.subject.value,
      body: event.target.message.value,
      plateform: "PARTNER",
    };

    await Axios.post(server + "/support/create", params, config)
      .then((rsp) => {
        resetForm();
        this.setState({
          message: rsp.data.detail,
          loader: "",
          notification: true,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          message: error.response.data.hasOwnProperty("detail")
            ? error.response.data.detail
            : "Server error occured! contact to support team.",
          notification: true,
          loader: "",
        });
      });
  };
  render() {
    const { loader, message, notification } = this.state;
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <Notification
          isActive={notification}
          message={message}
          dismissAfter={3000}
          onDismiss={() => this.setState({ notification: false })}
        />
        <div className="card mt-4">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">Support</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <div className="card mt-4 p-4">
              <div className="card-body">
                <form onSubmit={this.create}>
                  <div className="form-group">
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      className="form-control rounded-custom"
                      autoFocus={true}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="10"
                      placeholder="Type a message..."
                      className="form-control rounded-custom"
                      required
                    ></textarea>
                  </div>
                  <div className="form-group text-right mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary px-3 rounded-custom"
                    >
                      Submit {loader}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
