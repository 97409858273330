import React, { Fragment, PureComponent } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Axios from "axios";
import Topbar from "./components/header/topbar";
import Sidebar from "./components/header/sidebar";
import DashboardRouter from "./components/routes/dashboard";
import { auth, server } from "./components/routes/env";

export default class App extends PureComponent {
  constructor(props) {
    super(props);
    if (
      Date.parse(new Date()) - Date.parse(localStorage.tokenDate) >
      2 * 60 * 60 * 1000
    ) {
      localStorage.removeItem("token");
      localStorage.removeItem("tokenDate");
    }

    // From wilyer auth
    var token = window.location.href.split("/")[4];
    var check = window.location.href.split("/")[3];

    if ( check !== "screen_settings" && check !== "schedule_screen" && check !== "playlist_settings") {
      if (token !== undefined) {
        localStorage.setItem("token", token);
        localStorage.setItem("tokenDate", new Date());
      }
    }

    this.state = {
      login: "wait",
    };
  }

  componentDidMount() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    Axios
      .post(server + "/account/verify", {}, config)
      .then((rsp) => {
        if(rsp.data.hasOwnProperty('is_partner')){
          this.setState({
            login: true,
          });
          if (window.location.href.includes("/auth/")) {
            window.location.href = "/dashboard";
          }
        }else{
          window.location.href = auth + "/logout";
        }
      })
      .catch((error) => {
        this.setState({
          login: false,
        });
        window.location.href = auth + "/logout";
      });
  }

  render() {
    const { login } = this.state;
    if (login !== "wait") {
      if (!login) {
        window.location.href = auth + "/auth/partner";
      }
    }

    return (
      <Fragment>
        <BrowserRouter>
          <Fragment>
            {login === "wait" || login === false ? "" : <Topbar />}
            <div
              className={
                login === "wait" || login === false
                  ? "container-fluid d-none"
                  : "container-fluid"
              }
            >
              <div className="row">
                <Sidebar />
                <Switch>
                  <Route path="/" component={DashboardRouter} />
                </Switch>
              </div>
            </div>
          </Fragment>
        </BrowserRouter>
      </Fragment>
    );
  }
}
