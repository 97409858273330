import React, { PureComponent } from "react";
import { Route, Switch } from "react-router-dom";

import dashboard from "../source/dashboard/dashboard";
import Clients from "../source/dashboard/clients";
import Licenses from "../source/dashboard/licenses";
import Willplay from "../source/dashboard/willplay";
// import Products from "../source/dashboard/products";
import Support from "../source/dashboard/support";
// Documentation
import Help from "../source/dashboard/documentation/help";
import Contact from "../source/dashboard/documentation/contact";
// Settings
import Settings from "../source/dashboard/settings";
// Updates
import Updates from "../source/dashboard/documentation/updates";

export default class Dashboard_Router extends PureComponent {
  render() {
    return (
      <Switch>
        {/* Updates */}
        <Route path="/updates" component={Updates} />

        {/* Settings */}
        <Route path="/settings" component={Settings} />

        {/* Documentation */}
        <Route path="/help" component={Help} />
        <Route path="/contact" component={Contact} />

        <Route path="/support" component={Support} />
        {/* <Route path="/products" component={Products} /> */}
        <Route path="/willplay" component={Willplay} />
        <Route path="/license" component={Licenses} />
        <Route path="/clients" component={Clients} />
        <Route path="/" component={dashboard} />
      </Switch>
    );
  }
}
