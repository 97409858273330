import React, { PureComponent } from "react";

export default class Settings extends PureComponent {
  render() {
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4  pl-3">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">Wilyer Partner Updates</h3>
          </div>
        </div>
        <div className="row justify-content-center my-4">
          <div className="col-lg-6">
            <div className="card p-4">
              <div className="header">
                <div className="header-body">
                  <h4 className="header-title"> Updates </h4>
                </div>
              </div>
              <h5 className="d-inline-block pt-5">
                <span className="badge bg-info mt-n1">
                  v2.0 - Current
                </span>
              </h5>
              <span className="text-muted ml-2"> – January 01, 2021</span>
              <ul className="mb-5">
                <li>New UI design.</li>
              </ul>
              <h5 className="d-inline-block">
                <span className="badge bg-info mt-n1">v1.0</span>
              </h5>
              <span className="text-muted ml-2"> – April 25, 2019</span>
              <ul>
                <li>
                  Initial release of Wilyer Signage! Lots more coming soon
                  though 😁
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
