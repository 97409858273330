import React, { PureComponent } from "react";
import { Notification } from "react-notification";
import Axios from "axios";
import Spinner from "../../components/Spinner";
import { config, server } from "../../routes/env";
import resetForm from "../../components/ResetForm";

export default class Settings extends PureComponent {
  state = {
    loader: "",
    whiteLoader: "",
    message: "",
    whiteLabelStatus: false,
    whiteLabelFile: "",
    notification: false,
  };

  componentDidMount = () => {
    Axios.get(server + "/account/whiteLabelStatus", config)
      .then((rsp) => {
        this.setState({
          whiteLabelStatus: rsp.data.status,
          whiteLabelFile: rsp.data.url,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  update_password = (event) => {
    event.preventDefault();
    var old_password = event.target.old_password.value,
      new_password =
        event.target.new_password.value === event.target.cnf_password.value;
    if (new_password) {
      this.setState({
        loader: <Spinner />,
      });
      const params = {
        old_password,
        new_password: event.target.new_password.value,
      };

      Axios.post(server + "/account/update_password", params, config)
        .then((rsp) => {
          resetForm();
          this.setState({
            message: rsp.data.detail,
            loader: "",
            notification: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            message: error.response.data.hasOwnProperty("detail")
              ? error.response.data.detail
              : "Server error occured! contact to support team.",
            notification: true,
            loader: "",
          });
        });
    } else {
      this.setState({
        message: "New Passwords not matching.",
        notification: true,
      });
    }
  };

  updateWhiteLabel = (event) => {
    event.preventDefault();
    this.setState({
      whiteLoader: <Spinner />,
    });

    const params = new FormData();
    params.append("status", this.state.whiteLabelStatus);
    params.append(
      "logo",
      event.target.logo.files[0] === undefined ? "" : event.target.logo.files[0]
    );

    Axios.put(server + "/account/whiteLabelStatusUpdate", params, config)
      .then((rsp) => {
        this.setState({
          whiteLoader: "",
          message: rsp.data.detail,
          notification: true,
        });
      })
      .catch((err) => {
        this.setState({
          message: err.response.data.hasOwnProperty("detail")
            ? err.response.data.detail
            : "Server error occured! contact to support team.",
          notification: true,
        });
      });
  };

  render() {
    const {
      loader,
      whiteLoader,
      message,
      whiteLabelStatus,
      whiteLabelFile,
      notification,
    } = this.state;
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <Notification
          isActive={notification}
          message={message}
          dismissAfter={3000}
          onDismiss={() => this.setState({ notification: false })}
        />
        <div className="card mt-4 pl-3">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">Account Settings</h3>
          </div>
        </div>
        <div className="row justify-content-center my-4 g-4">
          <div className="col-lg-6">
            <div className="card p-3">
              <div className="card-header bg-transparent">
                <h6 className="card-title">Change Password</h6>
              </div>
              <form onSubmit={this.update_password}>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="old_password">Old Password</label>
                    <input
                      type="password"
                      name="old_password"
                      id="old_password"
                      className="form-control rounded-custom"
                      required
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label htmlFor="new_password">New Password</label>
                    <input
                      type="password"
                      name="new_password"
                      id="new_password"
                      className="form-control rounded-custom"
                      required
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label htmlFor="cnf_password">Confirm Password</label>
                    <input
                      type="password"
                      name="cnf_password"
                      id="cnf_password"
                      className="form-control rounded-custom"
                      required
                    />
                  </div>
                  <div className="form-group text-right mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary rounded-custom px-3"
                    >
                      Update Password {loader}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card p-3">
              <div className="card-header bg-transparent">
                <h6 className="card-title">Change Whitelabel Settings</h6>
              </div>
              <form onSubmit={this.updateWhiteLabel}>
                <div className="card-body">
                  <div className="form-group">
                    <div className="mb-3">
                      <label
                        htmlFor="formFile"
                        className="form-label d-inline-none"
                      >
                        Your Logo
                      </label>{" "}
                      <br />
                      <label>
                        {whiteLabelFile === "" ? (
                          ""
                        ) : (
                          <img
                            src={whiteLabelFile}
                            className="mb-2"
                            height="40"
                            width="auto"
                          />
                        )}
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/png, image/jpg, image/jpeg"
                        id="formFile"
                        name="logo"
                      />
                    </div>
                  </div>
                  <div className="form-group mt-4">
                    <label htmlFor="">Enable/Disable white-labeling</label>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input mr-3"
                        type="checkbox"
                        id="whiteLabelSwitch"
                        style={{ width: "40px" }}
                        checked={whiteLabelStatus}
                        onChange={() =>
                          this.setState({
                            whiteLabelStatus: whiteLabelStatus ? false : true,
                          })
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="whiteLabelSwitch"
                      >
                        {whiteLabelStatus ? "Enabled" : "Disabled"}
                      </label>
                    </div>
                  </div>
                  <div className="form-group mt-4 text-right">
                    <button
                      type="submit"
                      className="btn btn-primary rounded-custom px-3"
                    >
                      Update Whitelabel Status {whiteLoader}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
