import React, { PureComponent } from "react";
import { Eye, EyeOff } from "react-feather";
import { Notification } from "react-notification";
import Axios from "axios";
import Modal from "../../components/modal";
import { server, config } from "../../routes/env";
import hideModal from "../../components/HideModal";
import resetForm from "../../components/ResetForm";
import Spinner from "../../components/Spinner";

export default class Clients extends PureComponent {
  state = {
    show_password_icon: <Eye size={14} />,
    password_type: "password",

    url: "/partner/user/read",
    users: [],
    next: null,
    total_users: 0,
    current_user: "",
    show_more_button: "Show more",
    spinner: (
      <Spinner
        color={
          localStorage.getItem("theme-mode") === "dark" ? "#b5b5b7" : "#686769"
        }
      />
    ),

    // add screen
    btn_spinner: <Spinner />,
    message: "",
    add_screen_loader: "",
    notification: false,
  };

  show_Password = () => {
    const { password_type } = this.state;
    this.setState({
      show_password_icon:
        password_type === "password" ? <EyeOff size={14} /> : <Eye size={14} />,
      password_type: password_type === "password" ? "text" : "password",
    });
  };

  componentDidMount = () => {
    this.read_clients(this.state.url);
  };

  read_clients = (url, search = null) => {
    this.setState({
      show_more_button: this.state.spinner,
    });
    Axios.get(server + url, config).then((rsp) => {
      this.setState({
        total_users: rsp.data.count,
        users: search
          ? rsp.data.results
          : this.state.users.concat(rsp.data.results),
        next:
          rsp.data.next == null
            ? null
            : rsp.data.next.replace(/^.*\/\/[^\/]+/, ""),
        show_more_button:
          rsp.data.next == null ? "All are caught up!" : "Show more",
      });
    });
  };

  search = (event) => {
    this.read_clients(this.state.url + "?search=" + event.target.value, true);
  };

  show_more = (url) => {
    this.read_clients(url);
  };

  add_user = (event) => {
    event.preventDefault();
    this.setState({
      add_user_loader: <Spinner />,
    });

    const params = {
      username: event.target.username.value,
      email: event.target.email.value,
      password: event.target.password.value,
    };

    Axios.post(server + "/partner/user/create", params, config)
      .then((rsp) => {
        resetForm(0);
        hideModal(0);

        this.setState({
          add_user_loader: "",
          notification: true,
          message: rsp.data.detail,
        });
        this.read_clients(this.state.url, true);
      })
      .catch((error) => {
        this.setState({
          add_user_loader: "",
          notification: true,
          message: error.response.data.detail,
        });
      });
  };

  add_screen = (event) => {
    event.preventDefault();
    this.setState({
      add_user_loader: <Spinner />,
    });

    const params = {
      name: event.target.name.value,
      user_id: this.state.current_user,
      otp: parseInt(event.target.otp.value),
    };

    Axios.post(server + "/partner/screen/create", params, config)
      .then((rsp) => {
        resetForm(1);
        hideModal(1);

        this.setState({
          add_user_loader: "",
          notification: true,
          message: rsp.data.detail,
        });
      })
      .catch((error) => {
        this.setState({
          add_user_loader: "",
          notification: true,
          message: error.response.data.detail,
        });
      });
  };

  render() {
    const {
      show_password_icon,
      password_type,
      users,
      show_more_button,
      total_users,
      next,
      add_user_loader,
      notification,
      message,
      add_screen_loader,
    } = this.state;
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <Notification
          isActive={notification}
          message={message}
          dismissAfter={4000}
          onDismiss={() => this.setState({ notification: false })}
        />
        <div className="card mt-4">
          <div className="d-flex row justify-content-between p-3">
            <div className="col-md-6">
              <h3 className="card-title h5 mb-0 mt-1">Clients</h3>
            </div>
          </div>
        </div>
        <div className="card py-2 mt-4 mb-5">
          <div className="card-header bg-transparent border-0 p-3">
            <div className="row justify-content-between g-2">
              <div className="col-md-4">
                <button
                  className="btn btn-sm btn-primary px-3"
                  data-toggle="modal"
                  data-target="#add_client_modal"
                >
                  Add Client
                </button>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control text-muted rounded-custom pl-3"
                  placeholder="Search by name"
                  onChange={this.search}
                  required
                />
              </div>
            </div>
          </div>

          <div className="card-body p-0 border-0">
            <div className="table-responsive">
              <table className="table table-striped table-hover mb-0">
                <thead>
                  <tr className="text-center text-muted">
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Joined</th>
                    <th>Operations</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((data, index) => (
                    <tr key={index}>
                      <td className="td-pt-3 text-center">#{index + 1}</td>
                      <td className="td-pt-3 text-center">{data.user.name}</td>
                      <td className="td-pt-3 text-center">
                        <a
                          target="_blank"
                          rel="noopner noreferrer"
                          className="text-decoration-none"
                          href={"mailto:" + data.user.email}
                        >
                          {data.user.email}
                        </a>
                      </td>
                      <td className="td-pt-3 text-center">
                        {new Date(data.user.date_joined).toDateString()}
                      </td>
                      <td className="text-center">
                        <button
                          className="btn btn-sm btn-primary"
                          data-toggle="modal"
                          data-target="#add_screen_modal"
                          onClick={() =>
                            this.setState({ current_user: data.user.id })
                          }
                        >
                          Add Screen
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="card-footer bg-transparent border-0 p-3">
            <div className="row">
              <div className="col-6 text-muted">
                <h6 className="h6 pt-2 small">
                  Showing {users.length} of {total_users}
                </h6>
              </div>

              <div className="col-6 text-right">
                <button
                  className="btn btn-white btn-sm rounded-custom border px-3"
                  onClick={next == null ? null : () => this.read_clients(next)}
                >
                  {show_more_button}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal key="add_client_modal" id="add_client_modal" title="Add Client">
          <form onSubmit={this.add_user}>
            <div className="modal-body p-4 pt-0">
              <div className="form-group">
                <label htmlFor="username">Full Name</label>
                <input
                  type="text"
                  name="username"
                  id="username"
                  className="form-control"
                  autoFocus={true}
                  required={true}
                />
              </div>
              <div className="form-group pt-4">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="form-control"
                  required={true}
                />
              </div>
              <div className="form-group pt-4">
                <label htmlFor="email">Password</label>
                <div className="input-group">
                  <input
                    type={password_type}
                    name="password"
                    id="password"
                    className="form-control password-field"
                    required
                  />
                  <span
                    type="button"
                    className="input-group-text bg-default"
                    onClick={this.show_Password}
                  >
                    {show_password_icon}
                  </span>
                </div>
              </div>
              <div className="form-group text-right pt-4">
                <button className="btn btn-primary btn-sm mr-1">
                  Add Client {add_user_loader}
                </button>
                <button
                  type="reset"
                  className="btn btn-danger btn-sm lift rounded-custom px-3"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </Modal>

        <Modal key="add_screen_modal" id="add_screen_modal" title="Add Screen">
          <form onSubmit={this.add_screen}>
            <div className="modal-body p-4 pt-0">
              <div className="form-group">
                <label htmlFor="otp">Screen OTP</label>
                <input
                  type="number"
                  name="otp"
                  id="otp"
                  className="form-control"
                  required={true}
                />
                <small className="text-muted">
                  Six digit code that appears on the digital screen.
                </small>
              </div>
              <div className="form-group mt-4">
                <label htmlFor="name">Screen Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control"
                  required={true}
                />
              </div>
              <div className="form-group text-right pt-4">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm rounded-custom px-3 mr-1"
                >
                  Add Screen {add_screen_loader}
                </button>
                <button
                  type="reset"
                  className="btn btn-danger btn-sm lift rounded-custom px-3"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </Modal>
      </main>
    );
  }
}
