import React, { PureComponent } from "react";
import Axios from "axios";
import { server, config } from "../../routes/env";
import Spinner from "../../components/Spinner";
import { Notification } from "react-notification";
import resetForm from "../../components/ResetForm";

export default class Clients extends PureComponent {
  state = {
    notification: false,
    message: "",
    loader: "",
  };

  add_screen = (event) => {
    event.preventDefault();
    this.setState({
      loader: <Spinner />,
    });

    const params = {
      email: event.target.email.value,
      pairing_code: event.target.pairing_code.value,
    };

    Axios.post(server + "/partner/willplay/create", params, config)
      .then((rsp) => {
        resetForm(0);
        this.setState({
          loader: "",
          notification: true,
          message: rsp.data.detail,
        });
      })
      .catch((error) => {
        this.setState({
          loader: "",
          notification: true,
          message: error.response.data.detail,
        });
      });
  };

  render() {
    const { notification, message, loader } = this.state;
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <Notification
          isActive={notification}
          message={message}
          dismissAfter={4000}
          onDismiss={() => this.setState({ notification: false })}
        />
        <div className="card mt-4">
          <div className="d-flex row p-3">
            <div className="col-md-6">
              <h3 className="card-title h5 mb-0 mt-1">Willplay</h3>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="card mt-4 p-4">
              <div className="card-header bg-transparent py-3">
                <h6 className="card-title mb-0">Activate Willplay Screen</h6>
              </div>
              <div className="card-body p-4">
                <form onSubmit={this.add_screen}>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      autoFocus={true}
                      required={true}
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label htmlFor="pairing_code">Screen OTP</label>
                    <input
                      type="text"
                      name="pairing_code"
                      id="pairing_code"
                      className="form-control"
                      required={true}
                    />
                    <small className="text-muted">
                      Six digit code that appears on the digital screen.
                    </small>
                  </div>
                  <div className="form-group text-right mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm px-3"
                    >
                      Activate {loader}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
